<template>
  <loader v-if="showloader == true"></loader>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid w-450px ps-15"
            placeholder="Recherche"
            @change="searchdata"
            v-model="searchValue"
          />
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!-- <button type="button" class="btn btn-light-primary me-3">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button> -->
          <button
            type="button"
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_maj_module"
            style="float: right"
            @click="maj('', 'ajouter')"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Ajouter
          </button>
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <table
            class="table table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 text-uppercase"
              >
                <th>#</th>
                <th>Nom <i class="fa fa-sort" @click="sort('name')" style="cursor:pointer !important;"></i></th>
                <th>date <i class="fa fa-sort" @click="sort('created_at')" style="cursor:pointer !important;"></i></th>
                <th>Status</th>
                <th>Liste Sequences</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td class="odd">{{ date_format(item.created_at) }}</td>
                <td class="odd" v-if="item.isActive == 0">
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                    style="color: red; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="odd" v-if="item.isActive == 1">
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                    style="color: #50cd89; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td v-if="item.sequence.length > 0">
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_ListeSequences"
                    @click="AffichelisteSequences(item.sequence)"
                    style="cursor: pointer; color: #009ef7"
                  >
                    <i class="fa fa-eye" aria-hidden="true" style="font-weight: bold; font-size: 18px;"></i>
                  </span>
                </td>
                <td v-else>&nbsp;</td>
                <td class="end">
                  <span class="action_icon"
                    ><i
                      class="fa fa-edit fa-2x"
                      @click="maj(item, 'editer')"
                      style="cursor: pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_maj_module"
                    ></i
                    >Edit</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row" style="margin-right:0">
              <div class="col-sm-2 col-md-2 d-flex align-items-center justify-content-center justify-content-md-start">
                  <label
            ><select
              class="form-select form-select-sm form-select-solid select_page"
              @change="searchdata"
              v-model="limit"
              style="cursor:pointer"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="100000">All</option>
            </select></label
          >
              </div>
              <div 
              class="col-sm-10 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end"
              >
                  <paginate
                      :page-count="totalpage"
                      :page-range="3"
                      :margin-pages="2"
                      :click-handler="clickCallback"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :container-class="'pagination'"
                      :page-class="'page-item'"
                  >
                  </paginate>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- *****************modal ajouter module***************** -->
  <div class="modal fade" tabindex="-1" id="kt_modal_maj_module">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ajouter Thematique</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetData()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <form class="row g-3">
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="name" class="form-label required">Nom </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control m-2"
                  v-model="add_data.name"
                />
              </div>
              <div class="col-md-6">
                <label for="isActive" class="form-label required"
                  >Status
                </label>
                <select class="form-select m-2" v-model="add_data.isActive">
                  <option value="1">Activer</option>
                  <option value="0">Desactiver</option>
                </select>
              </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
              <div class="col-md-12 padding_left_none">
                <label for="abonnement" class="form-label">Sequences : </label>
                <Multiselect
                  v-model="selectSequence"
                  mode="tags"
                  :object="true"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :options="listeSequence"
                  label="name"
                  trackBy="name"
                />
              </div>
            </div>
            <div class="row">&nbsp;</div>
          </form>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetData()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="submit()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- *****************modal Liste Thematiques***************** -->
  <div class="modal fade" tabindex="-1" id="kt_modal_ListeSequences">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Liste Sequence</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetDataSequence()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom border-gray-200"
              >
                <th>#</th>
                <th>Nom</th>
                <th>Order</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(a, index) in GListeSequence" :key="a.id">
                <td>{{ index + 1 }}</td>
                <td>{{ a.name }}</td>
                <td>
                  <input type="number" v-model="a.order" min="0" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetDataSequence()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="save_order()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import loader from "../utility/loader.vue";
import Paginate from "vuejs-paginate-next";
export default {
  components: {
    Multiselect,
    loader,
    Paginate
  },
  data() {
    return {
      data: {},
      add_data: {
        isActive: 1,
        id: 0,
      },
      //modules:{},
      selectModule: "",
      formMode: "ajouter",
      listeSequence: {},
      GListeSequence: {},
      selectSequence: [],
      showloader: false,

      totalpage:0,
      currentpage:1,
      limit:10,
      sum:0,
      offset:0,
      searchValue:"",
      orderStatus: "asc",
    };
  },
  methods: {
    sort(index) {
      if (this.orderStatus === 'asc') {
          this.orderStatus = 'desc';
          this.data = this.data.sort((a, b) => {
            return b[index].localeCompare(a[index]);
          });
      } else if (this.orderStatus === 'desc') {
          this.orderStatus = 'asc';
          this.data = this.data.sort((a, b) => {
            return a[index].localeCompare(b[index]);
          });
      };
    },
    date_format(value) {
      return moment(String(value)).format("MM/DD/YYYY hh:mm");
    },
    save_order() {
      this.showloader = true;
      const data = {};
      data["liste"] = this.GListeSequence;
      data["table"] = "sequences";

      ApiService.setHeader();
      ApiService.post("tests/order/InsertOrUpdate", data)
        .then((response) => {
          this.showloader = false;
          this.alertSucess();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    resetDataSequence() {
      this.GListeSequence = {};
    },
    AffichelisteSequences(sequence) {
      this.GListeSequence = sequence;
    },
    getListeSequence() {
      ApiService.setHeader();
      ApiService.get("formation/sequence")
        .then((result) => {
          this.listeSequence = result.data;
          this.traitement_sequence();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getListeThematique() {
      this.showloader = true;
      this.offset = this.limit * (this.currentpage-1);
      ApiService.setHeader();
      ApiService.query("formation/thematique",{
        params:{
            searchValue: this.searchValue,
            limit:this.limit,
            offset:this.offset
        }
      }).then(async (result) => {
          this.data = await result.data.liste;
          this.sum = await result.data.sum;
          this.initpage();
          this.showloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit() {
      this.showloader = true;
      const data_select_tmp = [];
      this.selectSequence.forEach(async (item, index) => {
        await data_select_tmp.push(item.id);
      });

      this.add_data["listeIdSequence"] = data_select_tmp;

      this.add_data["formMode"] = this.formMode;
      ApiService.post("formation/thematique/InsertOrUpdate", this.add_data)
        .then((response) => {
          this.showloader = false;
          this.alertSucess();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    maj(item, val) {
      this.formMode = val;
      this.resetData();

      if (val == "editer") {
        //this.selectModule = item.modules;
        const data_tmp = JSON.parse(JSON.stringify(item));
        this.add_data = data_tmp;
        this.traitement_select_sequence(item.sequence);
      } else {
        this.selectSequence = [];
      }
    },
    traitement_select_sequence(data) {
      data.forEach(async (item, index) => {
        data[index]["value"] = await item.id;
        this.selectSequence.push(item);
      });
    },
    traitement_sequence() {
      this.listeSequence.forEach(async (item, index) => {
        this.listeSequence[index]["value"] = await item.id;
      });
    },
    resetData() {
      this.add_data = {
        isActive: 1,
        id: 0,
      };
      this.selectSequence = [];
    },
    alertSucess() {
      Swal.fire({
        text: "Enregistrer avec succes!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        location.reload();
      });
    },
    clickCallback (pageNum){
      this.currentpage = pageNum;
      this.getListeThematique();
    },
    async initpage(){
      this.totalpage = await Math.ceil(this.sum / this.limit);
    },
    async searchdata(){
      this.currentpage = await 1;
      this.getListeThematique();
    }
  },
  mounted() {
    this.getListeThematique();
    //this.getListeModule();
    this.getListeSequence();
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
